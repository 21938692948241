import React, { useRef, useState } from "react";
import Heading from "../Typography/Heading";
import logo from "../../resources/images/logo.svg";
import tipImg1 from "../../resources/images/tipsImg1.png";
import tipImg2 from "../../resources/images/tipsImg2.png";
import tipImg3 from "../../resources/images/tipsImg3.png";
import check from "../../resources/images/check.png";
import Button from "../Button/Button";
import TermsPopup from "../Popups/TermsPopup";
import UploadErrorPopup from "../Popups/UploadErrorPopup";
import ImageResize from "../ImageResize/ImageResize";
import styles from "./uploadPhoto.module.scss";


const UploadPhoto = (props: any) => {
	const [showTermsModal, setShowTermsModal] = useState(false);
	const [uploadErrorPopup, setUploadErrorPopup] = useState(false);
	const [imageFile, setImageFile] = useState(null);

	const [image, setImage] = useState<any>(null);

	let files: any;
	const handleImageUpload = async (event: any) => {
		console.log("event", event.target.files[0]);
		setImage(URL.createObjectURL(event.target.files[0]));
		setImageFile(event.target.files[0]);
		const reader = new FileReader();
		reader.onload = () => {
			setImage(reader.result as any);
		};
	};

	const backButtonHandler = () => {
		resetStateHandler();
	};

	const hideModal = () => {
		resetStateHandler();
	};

	const resetStateHandler = () => {
		setImage(null);
		setImageFile(null);
		setShowTermsModal(false);
		setUploadErrorPopup(false);
	};


	const getParams = new URLSearchParams(window.location.search);
	const cart_id = getParams.get("cart_id");
	const index = getParams.get("index");

	const uniqueKey_Id = cart_id + "_" + index;
	let termsCheckboxSelected: boolean | null = false;


	try {
		const termsCheckboxJSON: any = localStorage.getItem(uniqueKey_Id); //"{"termsCheckboxSelected":"true"}"
		const parseJSON = JSON.parse(termsCheckboxJSON); //{"termsCheckboxSelected":"true"}

		if (parseJSON) {
			termsCheckboxSelected = parseJSON.termsCheckboxSelected;
		}
	}
	catch (error) {
		console.log("error found", error);
	}


	return (
		<>
			<div style={{ display: image ? "none" : "" }} className={`${styles.uploadPhotoWrapper} ${showTermsModal || uploadErrorPopup ? styles.showPopup : ""}`}>
				<div className={styles.uploadWrapper}>
					<div className={styles.logo}>
						<img src={logo} alt="" />
					</div>
					<Heading headingType="default" textAlign="center" subHeading={true}
						subHeadingText="Use your phone to select photos from you library or take a photo." headingText="upload photo" />
					<div className={styles.btnWrapper}>

						{termsCheckboxSelected ?
							<>
								<Button type="file" customClass={styles.choosePhoto} bgColor="yellow" buttonText="choose a photo" cameraCapture={false} accept="image/*" name="cover" onChange={handleImageUpload} />
								<Button type="file" customClass={styles.mobileCapture} bgColor="transparent" buttonText="take a photo" cameraCapture={true} accept="image/*" onChange={handleImageUpload} />
							</>
							:
							<>
								<Button type="button" customClass={styles.choosePhoto} bgColor="yellow" buttonText="choose a photo" onClick={() => setShowTermsModal(!showTermsModal)} />
								<Button type="button" customClass={styles.mobileCapture} bgColor="transparent" buttonText="take a photo" cameraCapture={true} accept="image/*" onClick={() => setShowTermsModal(!showTermsModal)} />
							</>
						}

					</div>
				</div>
				<div className={styles.tipsWrapper}>
					<Heading headingType="default" textAlign="center" subHeading={false} headingText="tips for a perfect print" />
					<div className={styles.tipsImgWrapper}>
						<div className={styles.tipsImageContainer}>
							<img src={tipImg1} />
							<img className={styles.tick} src={check} />
							<p>1-2 faces</p>
						</div>
						<div className={styles.tipsImageContainer}>
							<img src={tipImg2} />
							<img className={styles.tick} src={check} />
							<p>Good lighting</p>
						</div>
						<div className={styles.tipsImageContainer}>
							<img src={tipImg3} />
							<img className={styles.tick} src={check} />
							<p>Simple background</p>
						</div>
					</div>
					<div className={styles.tipsListWrapper}>
						<p>Tips for a perfect print:</p>
						<ul>
							<li>Upload a high quality .jpg file, 5mb max </li>
							<li>Keep in mind that background will be removed, and photo will print in black & white</li>
							<li>Photos & logos must be your own private property</li>
							<li>We can’t reproduce copyrighted or trademarked images or logos unless legal permission has been obtained from the owner. For more information about logo printing, please contact our Business Consultants directly at 866-290-6854 (M-F, 9am – 7pm ET).</li>
						</ul>
					</div>
				</div>
				<div className={styles.copyRights}>
					<p>© 2022 Mars incorporated and its affiliates. All rights reserved. MMS.com</p>
				</div>

				{showTermsModal ?
					<TermsPopup
						handleImageUploadInParent={handleImageUpload}
						hideModal={hideModal}
					/>
					: null
				}

				{uploadErrorPopup ?
					<UploadErrorPopup
						hideModal={hideModal}
					/>
					: null
				}
			</div>
			{image ?
				<div className={styles.imageResizer} >
					<ImageResize
						src={image}
						imageFile={imageFile}
						backButtonHandler={backButtonHandler}
						setImage={setImage}
						checkTermsModal={setShowTermsModal}
					/>
				</div>
				: null
			}

		</>
	);
};

export default UploadPhoto;


