import React from "react";
import Button from "../Button/Button";
import PopupLayout from "./PopupLayout";
import styles from "./popup.module.scss";

function UploadErrorPopup(props: any) {

	const closeModal = () => {
		props.hideModal();
	};
	return (
		<>
			<PopupLayout position="center">
				<div className={styles.popupContent}>
					<p className={styles.customParagraph}>Unable to upload your file. Please select a different photo.</p>
					<Button type="button" buttonText="ok" bgColor="yellow" customClass="mt20" onClick={closeModal} />
				</div>
			</PopupLayout>
		</>
	);
}

export default UploadErrorPopup;