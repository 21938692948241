import React from "react";
import styles from "./popup.module.scss";

function PopupLayout(props: any) {
	return (
		<>
			<div className={`${styles.popupWrapper} ${props.customClass}
        ${props.position == "center" ? styles.popupCenter : ""}
        ${props.position == "bottom" ? styles.popupBottom : ""}`
			}>
				{props.children}
			</div>
		</>
	);
}

export default PopupLayout;