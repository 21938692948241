import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import close from "../../resources/images/Close.png";
import Button from "../Button/Button";
import Heading from "../Typography/Heading";
import PopupLayout from "./PopupLayout";
import styles from "./popup.module.scss";

function TermsPopup(props: any) {

	const [termsCheckboxSelected, setTermsCheckboxSelected] = useState(false);

	const handleCheckbox = () => {
		setTermsCheckboxSelected(!termsCheckboxSelected);
	};

	const handlStoreVlaue = (event: any) => {

		const getParams = new URLSearchParams(window.location.search);
		const cart_id = getParams.get("cart_id");
		const index = getParams.get("index");

		const uniqueKey_Id = cart_id + "_" + index;

		const checkTerms = { "termsCheckboxSelected": true };

		localStorage.setItem(uniqueKey_Id, JSON.stringify(checkTerms));
		props.handleImageUploadInParent(event);

	};

	const closeModal = () => {
		props.hideModal();
	};

	return (
		<>
			<PopupLayout position="bottom">
				<span className={styles.closePopup} onClick={closeModal} ><img src={close} alt="close icon" /></span>
				<Heading headingType="default" subHeading={false} headingText="terms & conditions" />
				<div className={styles.popupContent}>
					<p className="mt-0">Please read and agree to our terms & conditions before continuing.</p>
					<h4>My M&M’S Brand Store</h4>
					<strong>1. Introducation</strong>
					<p>These terms and conditions (the Terms) govern the order and purchase by you of any MY M&Ms® Chocolate Candies, including MY M&Ms® Chocolate Candies and pre-printed or custom-printed MY M&Ms® Milk Chocolate Candies (Product) and any merchandise, including, but not limited to, custom-printed merchandise (Merchandise) from mymms.com, an online candy ordering service, called MY M&Ms® (the Site), operated by Mars Retail Group, LLC. (Mars Retail Group), a subsidiary of Mars, Incorporated as well as your general use of the Site. By using this Site and/or placing an order for Product and/or Merchandise, you accept and agree to be bound by these Terms, as well as to the (a) General Terms & Conditions for Web Sites Operated by the Mars, Incorporated Family of Companies and the (b) Mars, Incorporated. Privacy Policy, which are hereby incorporated by reference.</p>

				</div>
				<div className={styles.acceptTerms}>
					<label className={styles.mb20}>
						<input type="checkbox" name="terms_conditions" onClick={handleCheckbox} />
						I have read, understand and agree to the Terms & Conditions
					</label>
					<Button
						disabled={!termsCheckboxSelected}
						type="file"
						customClass={styles.acceptTermsBtn}
						cameraCapture={false}
						bgColor={termsCheckboxSelected ? "yellow" : "gray"}
						onChange={(e: Event) => handlStoreVlaue(e)}
					/>
				</div>
			</PopupLayout>
		</>
	);
}

export default TermsPopup;