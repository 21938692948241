import React from "react";
import Heading from "../Typography/Heading";
import processing from "../../resources/images/processing.png";
import styles from "./photoProcessing.module.scss";

function PhotoProcessing() {
	return (
		<>
			<div className={styles.photoProcessingWrapper}>
				<img src={processing} alt="photo processing image" />
				<Heading headingType="default" subHeading={false} textAlign="center" customClass="mt20" headingText="processing your photo..." />
			</div>
		</>
	);
}

export default PhotoProcessing;