import React from "react";
import Button from "../Button/Button";
import PopupLayout from "./PopupLayout";
import styles from "./popup.module.scss";

function ProfanityPopup(props: any) {

	const closeModal = () => {
		props.hideModal();
	};

	const backToImageUpload = () => {
		props.setImage(null);
		props.checkTermsModal(false);
	};

	return (
		<>
			<PopupLayout position="center">
				<div className={styles.popupContent}>
					<p className={`${styles.customParagraph} mt-0`}>{props.errorMessage}</p>
					<Button type="button" customClass="mt20" buttonText="edit photo" bgColor="yellow" onClick={closeModal} />
					<Button type="button" buttonText="select new photo" bgColor="transparent" onClick={backToImageUpload} />
				</div>
			</PopupLayout>
		</>
	);
}

export default ProfanityPopup;