import React from "react";
import UploadPhoto from "./components/UploadPhoto/UploadPhoto";
import "./global.scss";

function App() {
	return (
		<UploadPhoto />
	);
}

export default App;
