import React from "react";
import styles from "./popup.module.scss";
import Button from "../Button/Button";
import PopupLayout from "./PopupLayout";

function ApiError(props: any) {

	const closeModal = () => {
		props.hideModal();
	};

	return (
		<PopupLayout position="center">
			<div className={styles.popupContent}>
				<p className={styles.customParagraph}>{props.errorMessage}</p>
				<Button type="button" buttonText="ok" bgColor="yellow" customClass="mt20" onClick={closeModal} />
			</div>
		</PopupLayout>
	);
}

export default ApiError;