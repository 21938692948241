import React from "react";
import styles from "./heading.module.scss";

const Heading = (props: any) => {
	let headingType;
	if (props.subHeading === true && props.headingType == "h1") {
		headingType = <h1>{props.headingText}<p>{props.subHeadingText}</p></h1>;
	}
	else if (props.subHeading === false && props.headingType == "h1") {
		headingType = <h1>{props.headingText}</h1>;
	}
	else if (props.subHeading === true && props.headingType == "h2") {
		headingType = <h2>{props.headingText}<p>{props.subHeadingText}</p></h2>;
	}
	else if (props.subHeading === false && props.headingType == "h2") {
		headingType = <h2>{props.headingText}</h2>;
	}
	else if (props.subHeading === true && props.headingType == "default") {
		headingType = <h3>{props.headingText}<p>{props.subHeadingText}</p></h3>;
	}
	else if (props.subHeading === false && props.headingType == "default") {
		headingType = <h3>{props.headingText}</h3>;
	}
	return (
		<>
			<div className={`${styles.sectionHeading} ${props.customClass} 
      ${props.textAlign == "center" ? styles.textCenter : ""}
      ${props.textAlign == "right" ? styles.textRight : ""}
      ${props.headingType}`}>
				{headingType}
			</div>
		</>
	);
};

export default Heading;