import React from "react";
import logo from "../../resources/images/logo.svg";
import redMnM from "../../resources/images/redMnM.png";
import Heading from "../Typography/Heading";
import styles from "./successMessage.module.scss";

function SuccessMessage() {
	return (
		<>
			<div className={styles.SuccessMessageWrapper}>
				<div className={styles.uploadWrapper}>
					<div className={styles.logo}>
						<img src={logo} alt="" />
					</div>
					<img src={redMnM} alt="" />
					<Heading headingType="default" textAlign="center" subHeading={true}
						subHeadingText="You’ve successfully uploaded your photo. Go back to the kiosk screen to check out your photo on an M&M! " headingText="you did it!" />
				</div>
				<div className={styles.copyRights}>
					<p>© 2022 Mars incorporated and its affiliates. All rights reserved. MMS.com</p>
				</div>
			</div>
		</>
	);
}

export default SuccessMessage;