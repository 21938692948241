import React, { useState } from "react";
import axios from "axios";
import { Slider } from "@mui/material";
import "cropperjs/dist/cropper.css";
import zoomIn from "../../resources/images/zoomIn.png";
import zoomOut from "../../resources/images/zoomOut.png";
import yellowMnM from "../../resources/images/yellowMnM.png";
import reset from "../../resources/images/reset.png";
import Button from "../Button/Button";
import Heading from "../Typography/Heading";
import Cropper from "react-cropper";
import PhotoProcessing from "../PhotoProcessing/PhotoProcessing";
import ProfanityPopup from "../Popups/ProfanityPopup";
import SuccessMessage from "../SuccessMessage/SuccessMessage";
import "../../cropper.scss";
import styles from "./imageResize.module.scss";
import ApiError from "../Popups/ApiError";

const maxSliderValue: any = 0.7;
const minSliderValue: any = 0.028;
const noOfZoomStep = 23;
const sliderZoomStep = maxSliderValue / noOfZoomStep;
const ZoomInitialValue = (maxSliderValue + minSliderValue) / 2;

const ImageResize = (props: any) => {

	const [cropData, setCropData] = useState("#");
	const [zoom, setZoom] = useState(ZoomInitialValue);
	const [cropper, setCropper] = useState<any>();
	const [isProcessing, setIsProcessing] = useState(false);
	const [profanityPopup, setProfanityPopup] = useState(false);
	const [apiError, setApiError] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);


	const handleZoom = (e: any, newValue: number | number[]) => {
		const zoomValue = Number(e.target.value);
		cropper.zoomTo(zoomValue);
		setZoom(zoomValue);
	};

	// Zoom In
	const zoomIncrease = () => {
		if (zoom >= maxSliderValue) {
			setZoom(maxSliderValue);
			return;
		}
		cropper.zoomTo(zoom + (sliderZoomStep));
		setZoom(zoom + (sliderZoomStep));
	};

	// Zoom Out
	const zoomDecrease = () => {
		if (zoom <= minSliderValue) {
			setZoom(minSliderValue);
			return;
		}
		cropper.zoomTo(zoom - (sliderZoomStep));
		setZoom(zoom - (sliderZoomStep));
	};

	// Image Reset
	const resetZoom = () => {
		cropper.reset();
		cropper.zoomTo(ZoomInitialValue);
		setZoom(ZoomInitialValue);
	};

	// Touch zoom
	const touchmove = (e: any) => {

		if (e.detail.ratio >= maxSliderValue) {
			e.preventDefault();
			setZoom(maxSliderValue);
			return;
		} else if (e.detail.ratio <= minSliderValue) {
			e.preventDefault();
			setZoom(minSliderValue);
			return;
		}
		setZoom(e.detail.ratio);
	};

	// back button handler
	const backButtonHandler = () => {
		props.backButtonHandler();
	};
	const hideModal = () => {
		resetStateHandler();
	};

	const resetStateHandler = () => {
		setProfanityPopup(false);
		setApiError(false);
	};

	// Generate random string
	function makeid(length: any) {
		let result = "";
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
	const indexValue = Math.floor(Math.random() * 4) + 1;

	const uniqueIndex = makeid(8) + "_" + indexValue;
	//console.log(makeid(8));

	// File Validation data

	let fileName = "";

	const params = new URLSearchParams(window.location.search);

	const cart_id = params.get("cart_id");
	const index = params.get("index");
	const uniqueKey = cart_id + "_" + index;


	const crop = async () => {
		setIsProcessing(true);
		const options = {
			width: 170,
			height: 170,
			minWidth: 170,
			minHeight: 170,
			maxWidth: 525,
			maxHeight: 525,
			imageSmoothingEnabled: true,
			imageSmoothingQuality: "high",
		};

		const base64URL = getRoundedCanvas(cropper.getCroppedCanvas(options)).toDataURL();

		fetch(base64URL)
			.then(res => res.blob())
			.then(async blob => {

				let newName = props.imageFile.name;

				if (props.imageFile.name !== "png" || props.imageFile.name !== "jpg" || props.imageFile.name !== "jpeg") {
					const tempFileName = props.imageFile.name;
					const name = tempFileName.substring(0, tempFileName.lastIndexOf(".")) || tempFileName;

					newName = name + ".png";
				}
				const file = new File([blob], newName, { type: "image/png" });

				console.log("fileCheck", file);
				const uploadAuthHeader = {
					headers: {
						Authorization:
							"Bearer eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImlhdCI6MTY3MTcxNDkwOH0.gBaFhJkXRTtm-RpnGYm1JNdoB18H5xjaC3T6xkTRGa0",
					}
				};

				try {
					let fileUploadUrl: any = "";
					if (process.env.REACT_APP_ENVIRONMENT_NAME === "dev") {
						fileUploadUrl = process.env.REACT_APP_FILE_UPLOAD_URL_DEV;
					} else {
						fileUploadUrl = process.env.REACT_APP_FILE_UPLOAD_URL;
					}
					const bodyFormData = new FormData();
					bodyFormData.append("file", file);
					console.log("filepath", fileUploadUrl);

					const response = await axios.post(fileUploadUrl, bodyFormData, uploadAuthHeader);
					console.log("response", response);
					console.log("crop donee", response.data.fileUrl);

					fileName = response.data.fileUrl;
					setCropData(base64URL);

				}
				catch (e) {
					console.log("error found");
					console.error(e);
				}

				try {
					const validateUrl: any = process.env.REACT_APP_CONTENT_VALIDATE_URL;

					console.log("cart_id", uniqueKey);

					const bodyParams = {
						path: fileName,
						cart_id: cart_id,
						index: index
					};

					const response = await axios.post(validateUrl, bodyParams, uploadAuthHeader);
					console.log("responseNew", response);

					if (response.data.status_code !== "60010") {
						console.log("error found1");
						setIsProcessing(false);
						setProfanityPopup(true);
					}
					else if (response.data.status_code === "0003") {
						console.log("third party error");
						// setIsProcessing(false);
						// setApiError(true);
					}
					else {
						console.log("error not found", response.data.status_code);
						setIsProcessing(false);
						setShowSuccess(true);
					}
				}
				catch (e) {
					console.log("error found");
					console.error(e);
				}
			});
	};

	const getRoundedCanvas = (sourceCanvas: any) => {
		const canvas = document.createElement("canvas");
		const context: any = canvas.getContext("2d");
		const { width, height } = sourceCanvas;
		canvas.width = width;
		canvas.height = height;

		context.imageSmoothingEnabled = true;
		context.drawImage(sourceCanvas, 0, 0, width, height);
		context.globalCompositeOperation = "destination-in";
		context.beginPath();
		context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
		context.fill();

		return canvas;
	};

	return (
		<>{isProcessing ?
			<PhotoProcessing />
			:
			<div style={{ display: isProcessing || showSuccess ? "none" : "block" }} className={`${styles.imageUploadModule} ${profanityPopup || apiError ? styles.showPopup : ""}`}>
				<div className={styles.uploadPhotoContainer}>
					<Cropper
						onInitialized={(instance) => {
							setCropper(instance);
						}}
						viewMode={0}
						zoomTo={ZoomInitialValue}
						checkOrientation={false}
						checkCrossOrigin
						center={true}
						rotatable={false}
						zoomable
						movable
						scalable
						cropBoxResizable={false}
						toggleDragModeOnDblclick={false}
						cropBoxMovable={false}
						dragMode="move"
						responsive={true}
						minCropBoxWidth={300}
						minCropBoxHeight={300}
						zoomOnWheel={false}
						zoomOnTouch={true}
						background={false}
						autoCrop
						src={props.src}
						aspectRatio={1}
						autoCropArea={0.9}
						guides={false}
						zoom={touchmove}
					/>
				</div>
				{/* Edit image module starts from here */}
				<div className={styles.editImageWrapper}>
					<Heading textAlign="center" headingType="default" subHeading={true} headingText="edit your image" subHeadingText="Since M&M’S are round, it doesn’t matter if your image is upside down or sideways!" />
					<div className={styles.controls}>
						<img src={zoomOut} onClick={zoomDecrease} />
						<label>
							<Slider
								value={zoom}
								min={minSliderValue}
								max={maxSliderValue}
								step={sliderZoomStep}
								aria-labelledby="zoom"
								onChange={handleZoom}
								className={styles.range}
							/>
						</label>
						<img src={zoomIn} onClick={zoomIncrease} />
					</div>
					<div className={styles.removeBgMsg}>
						<p>We carefully remove the background of every photo!</p>
						<img src={yellowMnM} />
					</div>
					<div className={styles.resetImage} onClick={resetZoom}>
						<img src={reset} /><p>Reset edits</p>
					</div>
					<div className={styles.buttonWrapper}>
						<Button
							type="button"
							bgColor="transparent"
							buttonText="back"
							onClick={backButtonHandler}
						/>
						<Button type="button" bgColor="yellow" buttonText="confirm" onClick={crop} />
					</div>
				</div>
				{profanityPopup ?
					<ProfanityPopup errorMessage="Oops! Looks like you used an inappropriate photo in your designs. Please either crop your image differently or choose another photo."
						hideModal={hideModal}
						setImage={props.setImage}
						checkTermsModal={props.checkTermsModal}
					/>
					: null}
				{apiError ?
					<ApiError errorMessage="Third part service is not available right now"
						hideModal={hideModal}
					/>
					: null}
			</div>
		}
		{showSuccess ?
			<SuccessMessage />
			: null}
		</>
	);
};

export default ImageResize;
