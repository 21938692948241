import React from "react";
import styles from "./button.module.scss";


const Button = (props: any) => {
	let buttonLayout;
	const { disabled = false, buttonText, onChange, accept, name, onClick, customClass, bgColor, type, cameraCapture = {} } = props;
	if (type == "file" && cameraCapture == false) {
		buttonLayout = <input disabled={disabled} type="file" placeholder={buttonText}
			className={
				`${customClass}
				${bgColor == "yellow" ? styles.btnYellow : ""}
				${bgColor == "gray" ? styles.btnGray : ""}
				${bgColor == "transparent" ? styles.btnHollow : ""}`
			}
			onChange={(event: any) => onChange(event)} onClick={(event: any) => {
				event.target.value = null;
			}} accept={accept} name={name} />;
	} else if (type == "file" && cameraCapture == true) {
		buttonLayout = <input disabled={disabled} capture type="file" placeholder={buttonText}
			className={
				`${customClass} 
				${bgColor == "yellow" ? styles.btnYellow : ""}
				${bgColor == "gray" ? styles.btnGray : ""}
				${bgColor == "transparent" ? styles.btnHollow : ""}`
			}
			onChange={(event: any) => onChange(event)} onClick={(event: any) => { event.target.value = null; }} accept={accept} name={name} />;
	}
	else if (type == "button") {
		buttonLayout = <button disabled={disabled} className={`${styles.btnDefault} ${customClass}
		${bgColor == "yellow" ? styles.btnYellow : ""}
		${bgColor == "gray" ? styles.btnGray : ""}
		${bgColor == "transparent" ? styles.btnHollow : ""}`} onClick={() => onClick()}>{buttonText}</button>;


	}

	return (
		<>
			{buttonLayout}
		</>
	);
};
export default Button;